<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-4 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <li class="is-active"><a>{{ $t('breadcrumb_company_settings') }}</a></li>
            <router-link tag="li" :to="{ name: 'company-models', params: { cid } }"><a>{{ $t('breadcrumb_company_models') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-4 md:px-0 pt-4 md:pt-8" v-if="company">
      <div class="container">
        <h1 class="title is-3">{{ $t('company_entry_models') }}</h1>
        <h2 class="subtitle is-4">{{ $t('company_entry_models_help') }}</h2>
        <sqr-button
          label="buttons_reset"
          @click="reset()"
          v-if="!company.entryModels"
        />
        <div class="entry-models">
          <draggable
            :value="company.entryModels"
            @input="fieldSet({ field: 'entryModels', value: $event })"
            :options="{}"
          >
            <div
              class="mb-1"
              v-for="model in company.entryModels"
              :key="model.id"
            >
              <div class="flex">
                <div class="">
                  <input
                    class="p-1 w-10 h-10 border rounded"
                    type="color"
                    :value="model.color"
                    @change="
                      modelChange(model.id, 'color', $event.target.value)
                    "
                    :disabled="disabled"
                  />
                </div>
                <div class="p-2">
                  <span class="icon">
                    <fa :icon="['fal', 'arrows']" fixed-widths />
                  </span>
                </div>
                <div class="p-2">
                  <span class="icon">
                    <fa :icon="['fal', model.icon]" fixed-widths />
                  </span>
                </div>
                <div class="p-2">
                  <span>{{
                    (model.name18 && model.name18[locale]) || model.name
                  }}</span>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </section>
    <section class="px-2 md:px-0 py-8" v-if="company">
      <div class="container">
        <update-info :value="company" />
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import entryModels from 'sqr-wotime-core/defaults/entryModels';
import Draggable from 'vuedraggable';

import company from './company';

import CompanyName from '@/components/CompanyName';
import UpdateInfo from '@/components/UpdateInfo';
import SqrButton from '@/sqrd/SqrButton';

export default {
  name: 'CompanyModels',
  mixins: [company],
  components: { Draggable, UpdateInfo, CompanyName, SqrButton },
  computed: {
    ...mapState('locale', ['locale']),
    ...mapGetters('perms', ['isManagerRW']),
    disabled() {
      return !this.isManagerRW;
    },
  },
  async beforeRouteLeave(from, to, next) {
    await this.send({ clear: true });
    next();
  },
  methods: {
    ...mapActions('company', ['fieldSet', 'send']),
    modelChange(id, field, value) {
      const models = JSON.parse(JSON.stringify(this.company.entryModels));
      const model = models.find(m => m.id === id);
      if (model) {
        model[field] = value;
        this.fieldSet({ field: 'entryModels', value: models });
      }
    },
    reset() {
      this.fieldSet({ field: 'entryModels', value: entryModels() });
    },
  },
};
</script>
