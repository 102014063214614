module.exports = function () {
  return [
    {
      id: 'work',
      icon: 'building',
      color: '#949494',
      name: 'worked',
      name18: {en: 'Work', fr: 'Travail', de: 'Arbeit'},
      note: '',
      input: 'from-until',
      from: '',
      until: '',
      duration: '',
      op: 'work',
      valid: false
    },
    {
      id: 'pause',
      icon: 'coffee',
      color: '#6B4D3F',
      name: 'break',
      name18: {en: 'Break', fr: 'Pause', de: 'Pause'},
      note: '',
      input: 'duration',
      duration: '',
      op: 'pause',
      valid: false
    },
    {
      id: 'travel',
      icon: 'briefcase',
      color: '#557280',
      name: 'travel',
      name18: {en: 'Short Travel', fr: 'Court voyage', de: 'Kürtzreise'},
      note: '',
      input: 'from-until',
      from: '',
      until: '',
      duration: '',
      op: 'work',
      valid: false
    },
    {
      id: 'recovery',
      icon: 'recycle',
      color: '#3748AC',
      name: 'recovery',
      name18: {en: 'Recovery', fr: 'Récup.', de: 'Erholung'},
      note: '',
      input: 'none',
      op: 'recovery',
      valid: true
    },
    {
      id: 'sick',
      icon: 'pills',
      color: '#00B4CD',
      name: 'sick',
      name18: {en: 'Sickday', fr: 'Maladie.', de: 'Krankentag'},
      note: '',
      input: 'day',
      day: 1,
      op: 'sick',
      valid: true
    },
    {
      id: 'vacation',
      icon: 'sun',
      color: '#1D8BF0',
      name: 'vacation',
      name18: {en: 'Vacation', fr: 'Vacances', de: 'Ferien'},
      note: '',
      input: 'day',
      day: 1,
      op: 'vacation',
      valid: true
    },
    {
      id: 'holiday',
      icon: 'bell',
      color: '#009FF1',
      name: 'bank holiday',
      name18: {en: 'Bank Holiday', fr: 'Férié', de: 'Feiertag'},
      note: '',
      input: 'day',
      day: 1,
      op: 'holiday',
      valid: true
    },
    {
      id: 'maternity-leave',
      icon: 'baby',
      color: '#00B4CD',
      name: 'maternity leave',
      name18: {en: 'Maternity Leave', fr: 'Congé de Maternité', de: 'Mutterschaftsurlaub'},
      note: '',
      input: 'day',
      day: 1,
      op: 'maternity-leave',
      valid: true
    },
    {
      id: 'sick-child',
      icon: 'child',
      color: '#00B4CD',
      name: 'sick child',
      name18: {en: 'Sick child', fr: 'Mal. enfant', de: 'Krankes Kind'},
      note: '',
      input: 'day',
      day: 1,
      op: 'sick-child',
      valid: true
    },
    {
      id: 'work-day',
      icon: 'wifi',
      color: '#30383E',
      name: 'remote work',
      name18: {en: 'Remote', fr: 'Télétravail', de: 'Fernarbeit'},
      note: '',
      input: 'day',
      day: 1,
      op: 'work',
      valid: true
    },
    {
      id: 'travel-day',
      icon: 'briefcase',
      color: '#1AAF54',
      name: 'travel day',
      name18: {en: 'Travel Day', fr: 'Jour de Voyage', de: 'Reisetag'},
      note: '',
      input: 'day',
      day: 1,
      duration: '',
      op: 'work',
      valid: true
    },
    {
      id: 'leave-day',
      icon: 'tree-alt',
      color: '#43A546',
      name: 'day leave',
      name18: {en: 'Day Leave (d)', fr: 'Jour d\'Absence ', de: 'Tagesurlaub'},
      note: '',
      input: 'day',
      day: 1,
      op: 'leave',
      valid: true
    },
    {
      id: 'leave-time',
      icon: 'leaf',
      color: '#80BA41',
      name: 'short leave',
      name18: {en: 'Short Leave', fr: 'Courte Absence', de: 'Kurzurlaub'},
      note: '',
      input: 'from-until',
      from: '',
      until: '',
      duration: '',
      op: 'leave',
      valid: false
    },
    {
      id: 'service',
      icon: 'compass',
      color: '#008B7D',
      name: 'military or civil service',
      name18: {en: 'Military / Civil Service', fr: 'Service Civil / Militaire', de: 'Militär / Öffentlicher Dienst'},
      note: '',
      input: 'day',
      day: 1,
      op: 'service',
      valid: true
    },
    {
      id: 'note',
      icon: 'sticky-note',
      color: '#FEE734',
      name: 'note',
      name18: {en: 'Note', fr: 'Note', de: 'Note'},
      note: '',
      input: 'none',
      op: 'note',
      valid: true
    }
  ];
};

